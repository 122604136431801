<template>
  <!-- 设备版本 -->
  <div>
    <div class="box-card">
      <!-- 面包屑导航 -->
      <div class="card_header">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>运维中心</el-breadcrumb-item>
          <el-breadcrumb-item>版本管理</el-breadcrumb-item>
          <el-breadcrumb-item>设备版本</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('63759829177400006e002e09')">删除</lbButton>
          <lbButton icon="xinzeng" @click="isAddDialog = true" v-if="isShowBtn('9019045e00524102020423f0')">新增</lbButton>
        </div>
      </div>
      <!-- 主要内容 -->
      <div style="text-align: left">
        <!-- 查询表单 -->
        <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" @submit.native.prevent>
          <el-form-item label="版本号：">
            <el-input v-model="queryForm.version_no" maxlength="30" @keyup.enter.native="onSubmit" @input="(e) => (queryForm.version_no = validFacility(e))" placeholder="请输入版本号"></el-input>
          </el-form-item>
          <el-form-item label="设备品牌：">
            <el-select popper-class="my-select" v-model="queryForm.version_brand_id" placeholder="请选择设备品牌" clearable @change="brandChange">
              <el-option v-for="(item, index) in brandList" :key="index" :label="item.brand_name" :value="item._id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号：">
            <el-select popper-class="my-select" v-model="queryForm.version_model_id" placeholder="请选择设备型号" clearable @change="onSubmit()">
              <el-option v-for="(item, index) in modelList" :key="index" :label="item.model_name" :value="item._id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
          </el-form-item>
        </el-form>
        <!-- 表格 -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="version_no" label="版本号"></el-table-column>
          <el-table-column prop="version_desc" label="版本说明"></el-table-column>
          <el-table-column prop="device_type" label="设备类型">
            <template slot-scope="scope">
              {{ getWayName('DeviceType', scope.row.device_type) }}
            </template>
          </el-table-column>
          <el-table-column prop="device_brand_name" label="设备品牌"></el-table-column>
          <el-table-column prop="device_model_name" label="设备型号"></el-table-column>
          <el-table-column label="创建时间" width="180">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(scope.row)" v-if="isShowBtn('63759829177400006e002e08')"></lbButton>
              <lbButton type="succes" icon="download" hint="下载" @click="downloadHandle(scope.row.version_addr)" v-if="isShowBtn('63759829177400006e002e0a')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
      </div>
    </div>
    <!-- -->
    <AddDeviceVersion :id="detailId" v-if="isAddDialog" @close="closeAddDeviceVersion"></AddDeviceVersion>
  </div>
</template>

<script>
import {
  getBrandList,
  getModelList,
} from "../../../api/deviceApi";
import { getVersionlist, delVersion } from "../../../api/maintenanceCenter";
import { mapState, mapGetters, mapMutations } from "vuex";
import AddDeviceVersion from './addDeviceVersion.vue';
export default {
  components: {
    AddDeviceVersion,
  },
  data() {
    return {
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        version_no: '',
        version_brand_id: '',
        version_model_id: '',
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 新增对话框
      isAddDialog: false,
      detailId: '',
      // 设备品牌下拉框
      brandList: [],
      // 设备型号下拉框
      modelList: [],
      // videopreview: false,
      // videopreviewurl: "",
      // videopreview_id: "",
      // loading: false,
    };
  },
  created() {
    this.fnGetBrandList();
    this.fnGetVersionPageList();
  },
  computed: {
    ...mapState(["queryObj", "queryStatus"]),
    ...mapGetters(["isAdmin", "getOptions", "getWayName"]),
  },
  methods: {
    async fnGetVersionPageList() {
      console.log(this.queryForm);
      const res = await getVersionlist({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        version_no: this.queryForm.version_no,
        version_brand_id: this.queryForm.version_brand_id,
        version_model_id: this.queryForm.version_model_id,
      });
      this.total = res && res.Code === 200 ? res.Data.TotalCount : 0;
      this.tableData = res && res.Code === 200 ? res.Data.DataList : [];
    },
    // 查询
    onSubmit() {
      this.queryForm.PageIndex = 1;
      // console.log(this.queryForm);
      this.fnGetVersionPageList();
    },
    // 每页条数改变
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val;
      this.fnGetVersionPageList();
    },
    // 页码改变
    handleCurrentChange(val) {
      this.queryForm.PageIndex = val;
      this.fnGetVersionPageList();
      // console.log(`当前页: ${val}`);
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val;
    },
    // 设备品牌下拉框
    async fnGetBrandList() {
      const res = await getBrandList({
        device_type: 9,
      });
      this.brandList = res.Data;
    },
    // 当设备品牌选中项发生改变时
    brandChange(val) {
      if (!val) {
        this.queryForm.version_brand_id = '';
        this.queryForm.version_brand_id = '';
        this.onSubmit();
        return
      }
      this.fnGetModelList(val);
      this.onSubmit();
    },
    // 设备型号下拉框
    async fnGetModelList(id) {
      const res = await getModelList({
        brand_id: id,
        device_type: 9,
      });
      // console.log('设备型号列表-----', res);
      this.modelList = res.Data;
    },
    // 批量删除
    batchDel() {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的设备版本, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
          }
          this.fndelVersion(arr)
        }).catch(() => {
          this.$msg.info('已取消删除');
        });
      } else {
        this.$msg.warning("请选择要删除的设备版本！");
      }
    },
    async fndelVersion(obj) {
      const res = await delVersion(obj);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message);
      }
      this.fnGetVersionPageList();
    },
    // 打开编辑对话框
    openEditForm(item) {
      this.isAddDialog = true;
      this.detailId = item._id;
    },
    // 下载版本
    downloadHandle(url) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link);
    },
    // 关闭新增对话框触发
    closeAddDeviceVersion(e) {
      this.isAddDialog = false;
      this.detailId = '';
      if (e) {
        this.onSubmit();
      } else {
        this.fnGetVersionPageList();
      }
    }
  },
  watch: {
  },
};
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
}
</style>
