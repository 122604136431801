<template>
  <el-dialog :close-on-click-modal="false" append-to-body :title="id ? '编辑版本' : '新增版本'" :visible.sync="isAddDialog"
    width="45%" @close="closeHandle">

    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" class="add_form" label-width="120px">
      <el-form-item label="版本号：" prop="version_no">
        <el-input v-model="addForm.version_no" maxlength="150" @keyup.enter.native="onSubmit"
          @input="(e) => (addForm.version_no = validFacility(e))" placeholder="请输入版本号"></el-input>
      </el-form-item>
      <el-form-item label="设备类型：" prop="device_type">
        <el-select popper-class="my-select" v-model="addForm.device_type" placeholder="请选择设备类型" disabled
          @change="typeSelectChange">
          <el-option v-for="item in getOptions('DeviceType')" :key="item.value" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备品牌：" prop="device_brand_id">
        <el-select popper-class="my-select" v-model="addForm.device_brand_id" placeholder="请选择设备品牌"
          @change="brandSelectChange">
          <el-option v-for="(item, index) in brandList" :key="index" :label="item.brand_name"
            :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备型号：" prop="device_model_id">
        <el-select popper-class="my-select" v-model="addForm.device_model_id" placeholder="请选择设备型号"
          @change="modelSelectChange">
          <el-option v-for="(item, index) in modelList" :key="index" :label="item.model_name"
            :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="版本文件：" prop="version_addr" class="version_addr_box">
        <el-input v-model="addForm.FileName" disabled v-if="addForm.version_addr"></el-input>
        <el-upload :action="''" :show-file-list="false" :http-request="fnUploadVersionAddr" :multiple="false">
          <el-button size="small" type="info">{{ addForm.version_addr ? "重新上传" : "上传" }}</el-button>
        </el-upload>
        <!-- <el-input v-model="addForm.version_addr" maxlength="30" @keyup.enter.native="onSubmit" @input="(e) => (addForm.version_no = validFacility(e))" placeholder="请输入版本号"></el-input> -->
      </el-form-item>
      <el-form-item label="版本说明：" prop="version_desc">
        <el-input type="textarea" maxlength="300" v-model.trim="addForm.version_desc" :rows="5" placeholder="请输入版本说明"
          @blur="addForm.version_desc = ((addForm.version_desc.replace(/\r\n|\n|\r/g, '<br/>')).replace(/\s/g, '')).replace(/<br\/>/g, '\n')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isAddDialog = false">取 消</el-button>
      <el-button type="primary" @click="submitAddForm()" v-preventReClick>确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getBrandList, getModelList } from "../../../api/deviceApi";
import { getVersionlist, delVersion, getVersionDetail, addVersion, updateVersion } from "../../../api/maintenanceCenter";
import { uploadFile } from "../../../api/securityApi";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
export default {
  props: ['id'],
  data() {
    return {
      isAddDialog: true,
      addForm: {
        version_no: "", // 版本号
        device_type: 9, // 设备类型
        device_brand_id: "", // 设备品牌
        device_brand_name: null,
        device_model_name: null,
        device_model_id: "", // 设备型号
        version_addr: "", // 文件地址
        FileName: "",
        version_desc: "", // 版本说明
      },
      addFormRules: {
        version_no: [{ required: true, message: '请输入版本号', trigger: 'blur' }], // 版本号
        device_type: [{ required: true, message: '请选择设备类型', trigger: 'blur' }], // 设备类型
        device_brand_id: [{ required: true, message: '请选择设备品牌', trigger: 'blur' }], // 设备品牌
        device_model_id: [{ required: true, message: '请选择设备型号', trigger: 'blur' }], // 设备型号
        version_addr: [{ required: true, message: '请上传版本文件', trigger: 'blur' }], // 文件地址
        version_desc: [{ required: true, message: '请输入版本说明', trigger: 'blur' }], // 版本说明
      },
      // 设备品牌下拉框
      brandList: [],
      // 设备型号下拉框
      modelList: [],
    }
  },
  created() {
    this.fnGetBrandList();
    if (this.id) {
      this.fnGetVersionDetail();
    }
  },
  mounted() { },
  computed: {
    ...mapState(["queryObj", "queryStatus"]),
    ...mapGetters(["isAdmin", "getOptions", "getWayName"]),
  },
  watch: {},
  methods: {
    // 获取详情数据
    async fnGetVersionDetail() {
      const res = await getVersionDetail({ id: this.id });
      if (res && res.Code === 200) {
        console.log('获取详情数据-----------', res);
        this.addForm.version_no = res.Data.version_no;
        this.addForm.device_type = res.Data.device_type;
        this.addForm.device_brand_id = res.Data.device_brand_id;
        this.addForm.device_brand_name = res.Data.device_brand_name;
        this.addForm.device_model_name = res.Data.device_model_name;
        this.addForm.device_model_id = res.Data.device_model_id;
        this.addForm.version_addr = res.Data.version_addr;
        var str = res.Data.version_addr.split('/');
        this.addForm.FileName = str[str.length - 1];
        this.addForm.version_desc = res.Data.version_desc;
        this.fnGetModelList(res.Data.device_brand_id);
      }
    },
    // 获取设备品牌下拉框
    async fnGetBrandList(type) {
      const res = await getBrandList({
        device_type: this.addForm.device_type,
      });
      this.brandList = res && res.Code === 200 ? res.Data : [];
    },
    // 获取设备型号下拉框
    async fnGetModelList(id) {
      const res = await getModelList({
        brand_id: id,
        device_type: this.addForm.device_type,
      })
      this.modelList = res && res.Code === 200 ? res.Data : [];
    },
    // 设备类型下拉框发生改变时
    typeSelectChange(type) {
      this.addForm.device_brand_id = '';
      this.addForm.device_brand_name = '';
      this.addForm.device_model_name = '';
      this.addForm.device_model_id = '';
      this.fnGetBrandList(type);
    },
    // 品牌下拉框发生改变时
    brandSelectChange(id) {
      this.addForm.device_model_name = '';
      this.addForm.device_model_id = '';
      var item = this.brandList.find(item => item._id === id);
      this.addForm.device_brand_name = item.brand_name;
      this.fnGetModelList(id);
    },
    // 型号下拉框发生改变时
    modelSelectChange(val) {
      var item = this.modelList.find(item => item._id === val);
      this.addForm.device_model_name = item.model_name;
    },
    // 上传版本文件
    async fnUploadVersionAddr(event) {
      var formData = new FormData();
      formData.append('file', event.file);
      formData.append('_filename', "version");
      const res = await uploadFile(formData);
      if (res.data && res.data.length > 0) {
        console.log('fnUploadVersionAddr---', res.data)
        this.addForm.version_addr = res.data[res.data.length - 1].FilePath
        this.addForm.FileName = res.data[res.data.length - 1].FileName
      }
    },
    // 提交表单验证
    submitAddForm() {
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var obj = this.addForm;
          if (this.id) {
            obj._id = this.id;
            this.fnUpdateVersion(obj);
          } else {
            this.fnAddVersion(obj);
          }
        } else {
          return false;
        }
      });
    },
    // 编辑请求
    async fnUpdateVersion(obj) {
      const res = await updateVersion(obj)
      if (res && res.Code === 200) {
        // this.isAddDialog = false;
        this.closeHandle(false);
      }
    },
    // 新增请求
    async fnAddVersion(obj) {
      const res = await addVersion(obj)
      if (res && res.Code === 200) {
        // this.isAddDialog = false;
        this.closeHandle(true);
      }
    },
    // 关闭对话框时触发
    closeHandle(e) {
      this.isAddDialog = false;
      console.log(e);
      // var flag = this.id || true;
      this.$emit('close', e);
    }
  },

}
</script>
<style scoped lang="less">
.add_form {
  /deep/ .el-select {
    width: 100%;
  }

  /deep/ .version_addr_box .el-form-item__content {
    display: flex;

    .el-input {
      margin-right: 10px;
    }
  }
}
</style>
